import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  getScreenSizes,
  Heading,
  Cross,
  Button,
  Dropdown
} from '@pretamanger/component-library'
import { css } from '@emotion/react'
import Cup from '#src/common/app/components/announcements/promotion/paper-cup'

export const CupIcon = styled(Cup)`
  ${props =>
    css`
      height: ${props.size}rem;
      width: 2rem;
    `}
`

export const ModalContainer = styled('div')`
  [role='dialog'] {
    ${tw`p-6 pt-10 flex flex-col xs:w-full overflow-hidden`}
    @media (min-width: ${getScreenSizes().sm}px) {
      width: 480px;
      height: 85%;
      min-height: 55vh;
      max-height: 90vh;
    }
  }
  [role='dialog'] > div {
    ${tw`overflow-y-auto`}
    height: calc(100% - 65px)
  }
  [role='alert']:empty {
    ${tw`p-0`}
  }
`

export const StyledHeading = styled(Heading)`
  ${tw`mb-2 px-1`}
`

export const CloseButton = styled(Cross)`
  ${tw`inline px-1 cursor-pointer`}
`
export const StyledButton = styled('button')`
  ${tw`focus:outline-none focus:shadow-outline flex ml-auto mr-1 my-1`}
`
export const CheckoutButton = styled(Button)`
  ${tw`order-2 flex-grow ml-4 py-3 px-4`}
`

export const DropdownQuantity = styled(Dropdown)`
  ${tw`order-1 w-16 m-0 mb-0!`}
  select {
    ${tw`border-gray-500`}
  }
  path {
    ${tw`stroke-current`}
  }
`

export const ModalFooter = styled('div')`
  ${tw`bg-white flex items-center absolute bottom-0 left-0 w-full p-6 pt-0`}
  box-shadow: 0 0 1rem 1.5rem rgba(255,255,255,1);
`

export const Separator = styled('hr')`
  ${tw`border-grey-100 mb-3`}
`

export const DisclaimerText = styled('p')`
  ${tw`font-medium text-base mb-8`}
`
