import styled from '@emotion/styled'
import tw from 'twin.macro'

export const ImageWrapper = styled('div')`
  ${tw`relative`}
  z-index:-1;
`

export const ProductCardWrapper = styled('div')`
  ${tw`pr-8`}
  z-index: -1;
`
