import slugify from 'slugify'
import { foodProductType } from '#constants'

const CJK_IDEOGRAPHS = /[\u3400-\u9FBF]/

export const getCategoryBySlug = (slug, categories) =>
  categories.find(category => category.slug === slug)
export const getCategoryWithSubCategoryBySlug = (slug, categories) => {
  const category = getCategoryBySlug(slug, categories)
  if (!category) return
  return {
    ...category,
    subcategories: category.subcategories || []
  }
}
export const filterSubCategories = categoryData => {
  categoryData.subcategories = categoryData?.subcategories.filter(
    subcategory => subcategory.id !== categoryData.id
  )
  return categoryData
}
export const getSubCategoryBySlug = (slug, categorySlug, categories) => {
  const category = getCategoryBySlug(categorySlug, categories)
  if (!category) return
  const subcategory = getCategoryBySlug(slug, category.subcategories || [])
  if (!subcategory) return
  return {
    ...category,
    subcategories: [subcategory]
  }
}

export const hasFoodExtras = product => {
  return (
    (product.productType?.key === foodProductType &&
      product.extras?.length > 0) ||
    product?.customisationOptions?.find(option => option.type === 'extras')
      ?.options.length > 0
  )
}

export const hasFoodCustomisationGroups = product => {
  return (
    product?.type === foodProductType && !!product?.customisationOptions?.length
  )
}

export const strictSlugify = val =>
  val.match(CJK_IDEOGRAPHS) ? val : slugify(val, { lower: true, strict: true })
export const isNew = date => {
  const now = new Date().getTime()
  const expires = new Date(date).getTime()
  return expires > now
}
export const hasMultiplePrices = product => {
  const cupSizes = product.baristaBeverageDetails?.cupSizes
  return (
    Object.values(cupSizes || {}).filter(cupSize => cupSize.value).length > 1
  )
}
