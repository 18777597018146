import React from 'react'
import PropTypes from 'prop-types'
import { LabelText } from './styles'

const LabelNode = ({ option, children }) => {
  const { name, localisedPrice } = option
  const srOnly = localisedPrice ? `${name}. ${localisedPrice}` : ''
  return (
    <>
      {children}
      <LabelText aria-hidden={!!localisedPrice}>{name}</LabelText>
      <span className='sr-only'>{srOnly}</span>
    </>
  )
}

LabelNode.propTypes = {
  children: PropTypes.node.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    localisedPrice: PropTypes.string
  }).isRequired
}

export default LabelNode
