import PropTypes from 'prop-types'
import { Button } from '@pretamanger/component-library'
import Translation from '#src/common/components/translation'

export const CoffeeWizardButton = ({ children, ...otherProps }) => {
  return (
    <Button styleType='quaternary' {...otherProps}>
      <Translation id='coffee.wizard.addToBag.button.label' />
      {children}
    </Button>
  )
}

CoffeeWizardButton.propTypes = {
  children: PropTypes.node,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

CoffeeWizardButton.defaultProps = {
  compact: false,
  disabled: false
}
