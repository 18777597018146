import PropTypes from 'prop-types'
import { Button } from '@pretamanger/component-library'

export const OutOfStock = ({ children }) => (
  <Button data-testid='unavailable' styleType='secondary' compact disabled>
    {children}
  </Button>
)

OutOfStock.propTypes = {
  children: PropTypes.node.isRequired
}
