import { useState } from 'react'
import { ScreenNames } from '@pretamanger/component-library/dist'
import Image from 'next/image'
import { ImageWrapper, ProductCardWrapper } from './styles'
declare type ImgElementStyle = NonNullable<
  JSX.IntrinsicElements['img']['style']
>

type Img = {
  src: string
  alt?: string
  height?: number
  width?: number
}

export type ImageProps = {
  /** A mapping of screen sizes to preferred image for given screen size. If there is a gap in provided screen sizes,
   * the image for the previously defined screen size is used, eg. with a definition of images for `sm` and `lg` screens,
   * on an `md` screen the image for `sm` gets used. */
  sources?: Partial<{ [_T in ScreenNames]: string }>
  /** URL of default image to display */
  img: Img
  imageWidths?: object
  defaultWidth?: number
  priority?: boolean
  ariaHidden?: boolean
  alt?: string
  useProductWrapper?: boolean
  customWrapperStyle?: any
  objectFit?: ImgElementStyle['objectFit']
}

const getImageSize = (imageWidths, width, defaultWidth) => {
  if (defaultWidth === 800) {
    return defaultWidth
  } else if (imageWidths && Object.keys(imageWidths).length > 0) {
    if (width <= imageWidths.xs) {
      return 'xs'
    } else if (width > imageWidths.xs && width <= imageWidths.sm) {
      return 'sm'
    } else if (width > imageWidths.sm && width <= imageWidths.md) {
      return 'md'
    } else if (width > imageWidths.md && width <= imageWidths.lg) {
      return 'lg'
    } else if (
      (width > imageWidths.lg && width <= imageWidths.xl) ||
      width >= imageWidths.xl
    ) {
      return 'xl'
    }
  } else {
    return defaultWidth
  }
}

const getWrapper = useProductWrapper => {
  return useProductWrapper ? ProductCardWrapper : ImageWrapper
}

const CustomNextImage: React.FC<ImageProps> = ({
  img,
  ariaHidden,
  imageWidths,
  defaultWidth,
  sources,
  alt,
  priority = false,
  useProductWrapper = false,
  customWrapperStyle = {},
  objectFit = 'cover'
}) => {
  const [imageSize, setImageSize] = useState({
    width: 1,
    height: 1
  })

  const getValue = (dimension, key) => {
    if (dimension && dimension > 0) {
      return dimension
    } else {
      return priority ? imageSize[key] : '100%'
    }
  }

  const imageLoader = ({ src, width }) => {
    const calculatedWidth = getImageSize(imageWidths, width, defaultWidth)
    if (typeof calculatedWidth === 'number') {
      return `${src}?fm=webp&w=${calculatedWidth}`
    } else if (calculatedWidth === undefined) {
      return src
    } else {
      return sources[calculatedWidth]
    }
  }

  const Wrapper = getWrapper(useProductWrapper)

  return (
    <Wrapper style={customWrapperStyle}>
      <Image
        // placeholder={"blur"}
        loader={imageLoader}
        src={img.src}
        layout='responsive'
        objectFit={objectFit}
        alt={alt ?? ''}
        priority={priority}
        onLoadingComplete={target => {
          setImageSize({
            width: target.naturalWidth,
            height: target.naturalHeight
          })
        }}
        width={getValue(img.width, 'width')}
        height={getValue(img.height, 'height')}
        {...(ariaHidden && { 'aria-hidden': true })}
      />
    </Wrapper>
  )
}

export default CustomNextImage
