import styled from '@emotion/styled'
import tw from 'twin.macro'

export const SingleSelectFieldset = styled('fieldset')`
  ${tw`mt-2 pt-1 px-1 pb-5 text-base`}
  > div {
    ${tw`flex items-center mt-4 xs:w-10/12 w-8/12`} :first-of-type {
      ${tw`mt-2`}
    }
    > span {
      ${tw`ml-auto`}
    }
    span:nth-of-type(2) {
      ${tw`pl-10`}
    }
    label > span:first-of-type {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  span,
  label {
    ${tw`items-center mb-0 flex`}
  }
`

export const IconWrapper = styled('i')`
  ${tw`mr-2 -ml-1`}
`
