import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'ramda/src/isEmpty'

const Price = ({ value, ...rest }) => (
  <>{!isEmpty(value) ? <span {...rest}>{value}</span> : ''}</>
)

Price.propTypes = {
  value: PropTypes.string
}

Price.defaultProps = {
  value: ''
}

export default Price
