import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { product } from '@proptypes'
import {
  LoadingIndicator,
  Modal,
  Heading
} from '@pretamanger/component-library'
import { useBodyLock } from '#src/common/hooks/useBodyLock'
import Translation, {
  translate,
  hasTranslationFor
} from '#src/common/components/translation'
import SingleSelect from '#src/coffee-wizard/components/single-select'
import MultiSelect from '#src/coffee-wizard/components/multi-select'
import { groupTypes } from '#src/coffee-wizard/constants'

import {
  getQuantityOptions,
  sortProductOptions,
  updateProductOptionsForShop
} from '#src/coffee-wizard/utils'
import useSetOptions, {
  setDefaultOption,
  setQuantity,
  setMultiSelectOption,
  setSingleSelectOption
} from '#src/coffee-wizard/hooks/useSetOptions'
import {
  getPrice,
  productVariantsHashMap,
  findProduct
} from '#src/coffee-wizard/utils/product-selection'

import {
  ModalContainer,
  StyledHeading,
  CheckoutButton,
  ModalFooter,
  DropdownQuantity,
  Separator,
  DisclaimerText,
  CupIcon
} from './styles'

import { OrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import ProductCalories from '#src/common/components/product-calories'

const LOADING_OPTIONS_ID = 'coffee.wizard.loading.options'
const ADDING_ITEM_TO_BAG = 'coffee.wizard.loading.addToBag'

export const CoffeeWizardModal = ({ onClose, product }) => {
  const { locale } = useRouter()
  const { bag, addItems } = useContext(OrderAheadBagContext)
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false)
  const [loadingIndicatorContentId, setLoadingIndicatorContentId] =
    useState(LOADING_OPTIONS_ID)
  const [error, setError] = useState(null)
  const [selectedOptions, dispatch] = useSetOptions()

  useBodyLock()
  const storeType = bag?.collectionDetails?.fulfillingShopType

  const [mappedVariants, updatedProduct] = useMemo(() => {
    return [
      productVariantsHashMap(product?.variants),
      updateProductOptionsForShop(product, locale, storeType)
    ]
  }, [product, locale, storeType])
  const productWithSortedOptions = useMemo(
    () =>
      sortProductOptions(
        updatedProduct,
        locale,
        mappedVariants,
        selectedOptions
      ),
    [locale, updatedProduct, mappedVariants, selectedOptions]
  )
  const {
    milkOptions = [],
    flavours = [],
    extras = [],
    caffeine = [],
    toppings = [],
    cupSizes = []
  } = productWithSortedOptions?.baristaBeverageDetails || {}
  const { nutritionalData = [], baristaBeverageDetails } = product

  useEffect(() => {
    dispatch(setDefaultOption(productWithSortedOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const totalPrice = getPrice(
    locale,
    storeType,
    mappedVariants,
    selectedOptions
  )

  const onAddItemToBag = async (payload, variantSkus) => {
    try {
      await addItems({
        payload,
        variantSkus
      })
    } catch (error) {
      setError(error)
    }
  }

  const onAddToBag = async () => {
    setShowLoadingIndicator(true)
    const { milkOption, caffeineOption, cupSizeOption, subLineItems } =
      selectedOptions
    const payload = {
      customisedItems: [
        {
          productSku: product.sku,
          productQuantity: selectedOptions.quantity,
          milkOption,
          caffeineOption,
          cupSizeOption,
          subLineItems: subLineItems.map(({ price, ...otherAttr }) => otherAttr)
        }
      ]
    }

    // a customized product will always have variants,
    // hence we are sending this to find if updated Bag return any of them
    const variant = findProduct(mappedVariants, selectedOptions, storeType)

    setShowLoadingIndicator(true)
    setLoadingIndicatorContentId(ADDING_ITEM_TO_BAG)
    await onAddItemToBag(payload, [variant.sku])

    setShowLoadingIndicator(false)
    setLoadingIndicatorContentId(LOADING_OPTIONS_ID)
    onClose()
  }
  const nutritionalDisclaimerText = 'coffee.wizard.nutritional.disclaimer'

  const handleQuantityChange = useCallback(value => {
    dispatch(setQuantity(value))
    /* dispatch is generally safe to ignore */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const handleSingleSelectChange = useCallback(
    (groupId, value) => dispatch(setSingleSelectOption(groupId, value)),
    /* dispatch is generally safe to ignore */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  const handleMultiSelectChange = useCallback(
    (groupId, data, checked, quantity, qtyChange) => {
      dispatch(
        setMultiSelectOption(groupId, checked, data, quantity, qtyChange)
      )
    },
    /* dispatch is generally safe to ignore */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    []
  )

  if (error) {
    throw error
  }
  return (
    <ModalContainer data-testid='customise-coffee-wizard'>
      <Modal
        description={translate('coffee.wizard.description', locale)?.value}
        onClose={onClose}
        open
      >
        <form>
          <>
            <LoadingIndicator on={!product || showLoadingIndicator}>
              <LoadingIndicator.On>
                <Translation id={loadingIndicatorContentId} />
              </LoadingIndicator.On>
            </LoadingIndicator>
            {product && !showLoadingIndicator && (
              <>
                <StyledHeading level='h1' styleOverride='headingSm'>
                  {product.name}
                </StyledHeading>
                <ProductCalories
                  baristaBeverageDetails={baristaBeverageDetails}
                  nutritionalData={nutritionalData}
                />
                <Separator aria-hidden />
                {cupSizes.length > 1 && (
                  <>
                    <SingleSelect
                      options={cupSizes}
                      groupId={groupTypes.CUP_SIZES}
                      handleChange={handleSingleSelectChange}
                      Icon={CupIcon}
                    >
                      <Heading level='h2' styleOverride='heading2XsLight'>
                        <Translation id='coffee.wizard.cupSize' />
                      </Heading>
                    </SingleSelect>
                    <Separator aria-hidden />
                  </>
                )}
                {caffeine.length > 1 && (
                  <>
                    <SingleSelect
                      options={caffeine}
                      groupId={groupTypes.CAFFEINE}
                      handleChange={handleSingleSelectChange}
                    >
                      <Heading level='h2' styleOverride='heading2XsLight'>
                        <Translation id='coffee.wizard.caffeineOption' />
                      </Heading>
                    </SingleSelect>
                    <Separator aria-hidden />
                  </>
                )}
                {milkOptions.length > 1 && (
                  <>
                    <SingleSelect
                      options={milkOptions}
                      groupId={groupTypes.MILK_OPTIONS}
                      handleChange={handleSingleSelectChange}
                    >
                      <Heading level='h2' styleOverride='heading2XsLight'>
                        <Translation id='coffee.wizard.milkOption' />
                      </Heading>
                    </SingleSelect>
                    <Separator aria-hidden />
                  </>
                )}
                {flavours.length > 0 && (
                  <>
                    <MultiSelect
                      options={flavours}
                      groupId={groupTypes.FLAVOUR_OPTIONS}
                      handleChange={handleMultiSelectChange}
                      locale={locale}
                    >
                      <Heading level='h2' styleOverride='heading2XsLight'>
                        <Translation id='coffee.wizard.flavourOptions' />
                      </Heading>
                    </MultiSelect>
                    <Separator aria-hidden />
                  </>
                )}
                {extras.length > 0 && (
                  <>
                    <MultiSelect
                      options={extras}
                      groupId={groupTypes.EXTRA_OPTIONS}
                      handleChange={handleMultiSelectChange}
                    >
                      <Heading level='h2' styleOverride='heading2XsLight'>
                        <Translation id='coffee.wizard.extraOptions' />
                      </Heading>
                    </MultiSelect>
                    <Separator aria-hidden />
                  </>
                )}
                {toppings.length > 0 && (
                  <MultiSelect
                    options={toppings}
                    groupId={groupTypes.TOPPINGS}
                    handleChange={handleMultiSelectChange}
                  >
                    <Heading level='h2' styleOverride='heading2XsLight'>
                      <Translation id='coffee.wizard.toppings' />
                    </Heading>
                  </MultiSelect>
                )}
              </>
            )}
          </>
          {hasTranslationFor(nutritionalDisclaimerText, locale) &&
            !showLoadingIndicator && (
              <DisclaimerText>
                <Translation id={nutritionalDisclaimerText} />
              </DisclaimerText>
            )}
          {product && !showLoadingIndicator && (
            <ModalFooter>
              <span className='pr-2'>
                <Translation id='bag.item.quantity.label' />
              </span>
              <DropdownQuantity
                id='quantity'
                current={`${selectedOptions.quantity}`}
                hideLabel
                label=''
                onSelect={handleQuantityChange}
                options={getQuantityOptions(locale)}
              />
              <CheckoutButton onClick={onAddToBag} data-testid='add-to-bag'>
                <Translation id='product.add-to-bag.label' />
                <span aria-hidden> | </span>
                <span className='sr-only'>{product.name}</span>
                {totalPrice}
              </CheckoutButton>
            </ModalFooter>
          )}
        </form>
      </Modal>
    </ModalContainer>
  )
}

CoffeeWizardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  product: product
}
