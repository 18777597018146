import range from 'ramda/src/range'
import {
  coffeeCentricStoreType,
  baristaProductType
} from '#src/common/constants'
import { translate } from '#src/common/components/translation/'
import isEmpty from 'ramda/src/isEmpty'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import {
  cupSizeOptions,
  mapCanCupSizeOptions,
  caffeineDisplayOrder,
  milkOptionsDisplayOrder,
  mapAttributesToTranslationId,
  milkOptions,
  caffeineOptions
} from '#src/coffee-wizard/constants'
import { getHashMapKey } from '#src/coffee-wizard/utils/product-selection'

export const isCoffeeWizardProduct = product => {
  return (
    product.productType?.key === baristaProductType &&
    product.canHaveVariants === true
  )
}

export const getQuantityOptions = locale => {
  const maxQuantity = Math.max(1, 10)
  return range(1, maxQuantity + 1).map(i => ({
    id: `qty-option-${i}`,
    value: String(i),
    label: String(i),
    ariaLabel: translate('bag.item.quantity.option.aria', locale, {
      count: String(i)
    })?.value
  }))
}

const setPricesForCupSizes = (
  cupSizes,
  mappedVariants = {},
  selectedOptions = {}
) => {
  const {
    milkOption = milkOptions.NO_MILK,
    caffeineOption = caffeineOptions.CAFFEINATED
  } = selectedOptions
  return cupSizes.map((option, index) => {
    const variant =
      mappedVariants[
        getHashMapKey(
          caffeineOption,
          milkOption,
          cupSizeOptions[mapCanCupSizeOptions[option.key]]
        )
      ]
    return {
      ...option,
      ...(variant?.price?.localisedPrice && {
        localisedPrice: variant?.price?.localisedPrice
      }),
      size: index * 0.4 + 1.5
    }
  })
}

export const sortProductOptions = (
  product,
  locale,
  mappedVariants,
  selectedOptions
) => {
  const {
    caffeine = [],
    milkOptions = [],
    cupSizes = []
  } = product?.baristaBeverageDetails || {}

  return {
    ...product,
    baristaBeverageDetails: {
      ...product?.baristaBeverageDetails,
      caffeine: sortByDisplayOrder(caffeine, caffeineDisplayOrder, locale),
      cupSizes: setPricesForCupSizes(cupSizes, mappedVariants, selectedOptions),
      milkOptions: sortByDisplayOrder(
        milkOptions,
        milkOptionsDisplayOrder,
        locale
      )
    }
  }
}

const sortByDisplayOrder = (options, displayOrder, locale) => {
  const order = displayOrder[locale] || displayOrder.default
  return order
    .map(key => find(propEq('key', key))(options))
    .filter(o => typeof o !== 'undefined')
}

export const isCoffeeCentricStore = storeType =>
  storeType === coffeeCentricStoreType

// To be migrated to platform-api https://pretamanger.atlassian.net/browse/BPS-1084
export const updateProductOptionsForShop = (product, locale, storeType) => {
  const {
    caffeine = {},
    milkOptions = {},
    flavours = {},
    extras = {},
    cupSizes = {},
    toppings = {}
  } = product?.baristaBeverageDetails || {}

  return {
    ...product,
    baristaBeverageDetails: {
      ...product?.baristaBeverageDetails,
      ...(!isEmpty(caffeine) && {
        caffeine: transformCaffeineOptions(caffeine, locale, storeType)
      }),
      ...(!isEmpty(cupSizes) && {
        cupSizes: transformCupSizes(cupSizes, locale)
      }),
      ...(!isEmpty(milkOptions) && transformMilkOptions(milkOptions, locale)),
      ...(!isEmpty(flavours) && {
        flavours: transformSyrupOptions(flavours, locale)
      }),
      ...(!isEmpty(extras) && {
        extras: transformExtrasOption(extras, locale)
      }),
      ...(!isEmpty(toppings) && {
        toppings: transformToppingsOptions(toppings, locale)
      })
    }
  }
}

export const transformCaffeineOptions = (caffeine, locale, storeType) => {
  const defaultOption = 'canBeCaf'
  const isCoffeeCentricShop = isCoffeeCentricStore(storeType)

  let { canBeDecafPod = {}, ...caffieneOptions } = caffeine

  caffieneOptions = {
    ...caffieneOptions,
    canBeDecaf: {
      ...caffeine.canBeDecaf,
      isSelected: !!(!isCoffeeCentricShop
        ? caffeine?.canBeDecaf?.isSelected
        : canBeDecafPod?.isSelected),
      value: !!(!isCoffeeCentricShop
        ? caffeine?.canBeDecaf?.value
        : canBeDecafPod?.value)
    }
  }

  const options = Object.keys(caffieneOptions)
    .map(key => ({
      ...caffieneOptions[key],
      key,
      name: translate(mapAttributesToTranslationId[key], locale)?.value
    }))
    .filter(option => option.value)

  if (options.length > 0) {
    options.push({
      key: defaultOption,
      isSelected: !options.some(option => option?.isSelected),
      value: true,
      name: translate(mapAttributesToTranslationId.canBeCaf, locale)?.value
    })
  }
  return options
}

const transformMilkOptions = (milkOptions, locale) => {
  const options = []
  Object.keys(milkOptions).forEach(key => {
    if (milkOptions[key]?.value) {
      options.push({
        ...milkOptions[key],
        name: translate(mapAttributesToTranslationId[key], locale)?.value,
        key
      })
    }
  })
  return {
    milkOptions: options
  }
}

const transformSyrupOptions = flavours => {
  const { availableSyrups = [], canAddSyrup = false } = flavours || {}
  return canAddSyrup ? availableSyrups : []
}

const transformExtrasOption = (extras, locale) => {
  const {
    availableExtraShots = [],
    canAddExtraShot = false,
    canAddWhippedCream = false,
    canAddLemonWedge = false,
    availableWhippedCream = [],
    availableLemonWedges = []
  } = extras || {}
  const options = []
  if (canAddExtraShot && availableExtraShots.length) {
    options.push({
      ...availableExtraShots[0],
      value: canAddExtraShot,
      isSelected: false,
      label: mapAttributesToTranslationId.canAddExtraShot,
      name: translate(mapAttributesToTranslationId.canAddExtraShot, locale)
        ?.value
    })
  }
  if (canAddWhippedCream && availableWhippedCream.length) {
    options.push({
      ...availableWhippedCream[0],
      value: canAddWhippedCream,
      isSelected: false,
      label: mapAttributesToTranslationId.canAddWhippedCream,
      name: translate(mapAttributesToTranslationId.canAddWhippedCream, locale)
        ?.value
    })
  }
  if (canAddLemonWedge && availableLemonWedges.length) {
    options.push({
      ...availableLemonWedges[0],
      value: canAddLemonWedge,
      isSelected: false
    })
  }

  return options
}

const transformCupSizes = (cupSizes, locale) => {
  const options = []
  Object.keys(cupSizes).forEach(key => {
    if (cupSizes[key]?.value) {
      options.push({
        ...cupSizes[key],
        name: translate(mapAttributesToTranslationId[key], locale)?.value,
        key
      })
    }
  })
  return options
}

const transformToppingsOptions = toppings => {
  const { availableToppings = [], canAddToppings = false } = toppings || {}
  return canAddToppings ? availableToppings : []
}
