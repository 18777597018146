import invertObj from 'ramda/src/invertObj'
import { localisePrice } from '#lib/locale'
import prop from 'ramda/src/prop'
import { isCoffeeCentricStore } from '#src/coffee-wizard/utils'
import { localeToCurrencyMap } from '#src/common/constants'
import {
  milkOptions,
  mapIsCupSizeOptions,
  caffeineOptions,
  mapIsMilkOptions,
  mapIsCaffeineOptions,
  cupSizeOptions
} from '#src/coffee-wizard/constants'

const getOptionKey = (options, mappedOptions, defaultValue) =>
  mappedOptions[invertObj(options).true] || defaultValue

export const getHashMapKey = (caffeineOption, milkOption, cupSizeOption) =>
  `${caffeineOption}#${milkOption}#${cupSizeOption}`

export const productVariantsHashMap = (variants = []) =>
  variants.reduce((obj, variant) => {
    const {
      baristaAttributes: { caffeine, milkOptions: _milkOptions, cupSizes } = {}
    } = variant
    const caffeineOption = getOptionKey(
      caffeine,
      mapIsCaffeineOptions,
      caffeineOptions.CAFFEINATED
    )
    const milkOption = getOptionKey(
      _milkOptions,
      mapIsMilkOptions,
      milkOptions.NO_MILK
    )
    const cupSizeOption = getOptionKey(
      cupSizes,
      mapIsCupSizeOptions,
      cupSizeOptions.NONE
    )
    obj[getHashMapKey(caffeineOption, milkOption, cupSizeOption)] = variant
    return obj
  }, {})

export const findProduct = (mappedVariants, selectedOptions, storeType) => {
  const {
    milkOption,
    caffeineOption,
    cupSizeOption = cupSizeOptions.NONE
  } = selectedOptions
  const caffeineOptionForStore =
    isCoffeeCentricStore(storeType) && caffeineOption === caffeineOptions.DECAF
      ? caffeineOptions.DECAF_POD
      : caffeineOption
  return mappedVariants[
    getHashMapKey(caffeineOptionForStore, milkOption, cupSizeOption)
  ]
}

export const calculatePrice = (variant, subLineItems, locale, quantity = 1) => {
  const variantPrice = prop('price', variant) || {
    centAmount: 0,
    fractionDigits: 2
  }
  const { centAmount: totalVariantPrice, fractionDigits } = variantPrice
  const totalSubLineItemsPrice = subLineItems.reduce(
    (acc, curr) => (curr ? acc + curr.price?.centAmount * curr.quantity : acc),
    0
  )
  const totalPrice = (totalSubLineItemsPrice + totalVariantPrice) * quantity

  const totalPriceForQty = {
    fractionDigits,
    centAmount: totalPrice,
    currencyCode: localeToCurrencyMap[locale]
  }

  return localisePrice(totalPriceForQty, locale)
}

export const getPrice = (locale, storeType, variantMap, selectedOptions) => {
  const { subLineItems: _subLineItems, quantity } = selectedOptions
  const subLineItems = _subLineItems.filter(item => !!item?.price)
  const variant = findProduct(variantMap, selectedOptions, storeType)
  return calculatePrice(variant, subLineItems, locale, quantity)
}
