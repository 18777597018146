import PropTypes from 'prop-types'
import React from 'react'
import { baristaBeverageDetailsShape, nutritionalDataShape } from '@proptypes'
import { nutritionalDataKeys } from '#constants'
import Translation from '#src/common/components/translation'

const ProductCalories = ({
  baristaBeverageDetails,
  classes,
  nutritionalData
}) => {
  const perServing =
    nutritionalData?.[0]?.[nutritionalDataKeys.calories]?.perServing

  if (!perServing) return null

  const cupSizes = baristaBeverageDetails?.cupSizes
  const cupSize =
    cupSizes &&
    Object.keys(
      Object.fromEntries(
        Object.entries(cupSizes).filter(([_, val]) => val?.isSelected)
      )
    )?.[0]
      ?.replace('canHave', '')
      ?.toLowerCase()

  return (
    <div className={classes} data-testid='product-calories'>
      {`${perServing} `}
      <Translation id='product.nutrientMeasurement.calories' />
      {cupSize && `/${cupSize}`}
    </div>
  )
}

ProductCalories.propTypes = {
  baristaBeverageDetails: baristaBeverageDetailsShape,
  classes: PropTypes.string,
  nutritionalData: nutritionalDataShape
}

ProductCalories.defaultProps = {
  baristaBeverageDetails: {},
  classes: '',
  nutritionalData: [{}]
}

export default ProductCalories
