import PropTypes from 'prop-types'
import { RadioButton, getColours } from '@pretamanger/component-library'
import { SingleSelectFieldset, IconWrapper } from './styles'
import Price from '#src/coffee-wizard/components/price'
import LabelNode from '#src/coffee-wizard/components/label-node'
import React from 'react'

const colours = getColours()
const SingleSelect = ({ options, groupId, handleChange, children, Icon }) => {
  const onChange = e => {
    const value = e.target.value
    handleChange(groupId, value)
  }

  return (
    <SingleSelectFieldset>
      <legend>{children}</legend>
      {options.map(option => {
        if (option?.value) {
          return (
            <div key={option.key}>
              <RadioButton
                name={groupId}
                label={
                  <LabelNode option={option}>
                    {Icon && (
                      <IconWrapper>
                        <Icon
                          size={option.size}
                          colour={colours['pretRed-700']}
                        />
                      </IconWrapper>
                    )}
                  </LabelNode>
                }
                defaultChecked={option?.isSelected}
                value={option.key}
                onChange={onChange}
                id={option.name}
              />

              <Price value={option?.localisedPrice} aria-hidden />
            </div>
          )
        }
      })}
    </SingleSelectFieldset>
  )
}

SingleSelect.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
      localisedPrice: PropTypes.string,
      value: PropTypes.oneOf[(PropTypes.string, PropTypes.bool)]
    })
  ).isRequired,
  groupId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  Icon: PropTypes.node
}

SingleSelect.defaultProps = {
  Icon: undefined
}

export default SingleSelect
