import styled from '@emotion/styled'
import tw from 'twin.macro'

export const MultiSelectFieldset = styled('fieldset')`
  ${tw`pt-1 px-1 pb-5 text-base`}
  > div {
    ${tw`mt-3`} :first-of-type {
      ${tw`mt-1 `}
    }
  }
  span,
  label {
    ${tw`items-center`}
  }
`

export const RowItem = styled('div')`
  > div {
    ${tw`flex mb-1 xs:w-10/12 w-8/12`}
    > span {
      ${tw`ml-auto mb-1`}
    }
    > div {
      ${tw`mt-0 mb-0 p-0 bg-transparent`}
    }
  }
`
