import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Select } from '@pretamanger/component-library'

export const SelectContainer = styled('div')`
  ${tw`ml-10`}
`

export const QtySelect = styled(Select)`
  ${tw`w-6/12`}
`
