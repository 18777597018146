import { useReducer } from 'react'
import {
  mapCanMilkOptions,
  mapCanCupSizeOptions,
  mapCanCaffeineOptions,
  milkOptions,
  cupSizeOptions,
  caffeineOptions,
  groupTypes
} from '#src/coffee-wizard/constants'
import {
  defaultCupSizeOption,
  defaultMilkOption,
  defaultCaffeineOption
} from '#src/common/constants'

const initialState = {
  caffeineOption: caffeineOptions.CAFFEINATED,
  milkOption: milkOptions.NO_MILK,
  cupSizeOption: cupSizeOptions.NONE,
  quantity: 1,
  subLineItems: []
}

const setDefaultOptions = product => {
  const {
    caffeine = [],
    milkOptions = [],
    cupSizes = []
  } = product?.baristaBeverageDetails || {}
  const caffeineKey =
    caffeine.find(option => option.isSelected)?.key || defaultCaffeineOption
  const milkOptionsKey =
    milkOptions.find(option => option.isSelected)?.key || defaultMilkOption

  const cupSizeOptionsKey =
    cupSizes.find(option => option.isSelected)?.key || defaultCupSizeOption

  return {
    ...initialState,
    caffeineOption: mapCanCaffeineOptions[caffeineKey],
    milkOption: mapCanMilkOptions[milkOptionsKey],
    cupSizeOption: mapCanCupSizeOptions[cupSizeOptionsKey],
    quantity: 1
  }
}

const actionTypes = Object.freeze({
  ...groupTypes,
  SET_DEFAULT_OPTION: 'SET_DEFAULT_OPTION'
})

export const setSingleSelectOption = (type, value) => ({ type, value })

export const setMultiSelectOption = (
  type,
  checked,
  data,
  quantity,
  qtyChange
) => ({
  type,
  checked,
  data,
  quantity,
  qtyChange
})

export const setDefaultOption = product => ({
  type: actionTypes.SET_DEFAULT_OPTION,
  options: setDefaultOptions(product)
})

export const setQuantity = value => ({
  type: actionTypes.QUANTITY,
  value: parseInt(value)
})

const reducer = (currentState = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DEFAULT_OPTION:
      return {
        ...action.options
      }
    case actionTypes.CAFFEINE:
      return {
        ...currentState,
        caffeineOption:
          mapCanCaffeineOptions[action.value || defaultCaffeineOption]
      }
    case actionTypes.CUP_SIZES:
      return {
        ...currentState,
        cupSizeOption:
          mapCanCupSizeOptions[action.value || defaultCupSizeOption]
      }
    case actionTypes.MILK_OPTIONS:
      return {
        ...currentState,
        milkOption: mapCanMilkOptions[action.value || defaultMilkOption]
      }
    case actionTypes.QUANTITY:
      return {
        ...currentState,
        quantity: action.value
      }
    case actionTypes.EXTRA_OPTIONS:
    case actionTypes.FLAVOUR_OPTIONS:
    case actionTypes.TOPPINGS: {
      const { checked, data, quantity = 1, qtyChange } = action
      const { sku, name, label, price } = data
      return {
        ...currentState,
        subLineItems: checked
          ? [
              ...currentState.subLineItems,
              { sku, quantity, name, label, price }
            ]
          : qtyChange
          ? [
              ...currentState.subLineItems.map(x =>
                x.sku === sku ? { ...x, quantity, price } : x
              )
            ]
          : currentState.subLineItems.filter(item => item.sku !== sku)
      }
    }
    default:
      return currentState
  }
}

const useSetOptions = () => useReducer(reducer, initialState)

export default useSetOptions
