import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox } from '@pretamanger/component-library'
import LabelNode from '#src/coffee-wizard/components/label-node'
import Price from '#src/coffee-wizard/components/price'

const CheckboxAndPrice = ({ groupId, option, onCheckboxChange }) => {
  return (
    <div>
      <Checkbox
        name={groupId}
        label={<LabelNode option={option} />}
        defaultChecked={option?.isSelected}
        onChange={e => onCheckboxChange(e, option)}
        id={option.name}
      />
      <Price value={option?.localisedPrice} aria-hidden />
    </div>
  )
}

CheckboxAndPrice.propTypes = {
  groupId: PropTypes.string.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    localisedPrice: PropTypes.string.isRequired,
    maxQty: PropTypes.number,
    defaultQty: PropTypes.number
  }).isRequired
}

export default CheckboxAndPrice
