import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, getScreenSizes, Heading } from '@pretamanger/component-library'

export const CardBody = styled.div`
  ${tw`relative`}
  ${tw`flex flex-row`}
  ${tw`sm:h-40`}
`

export const CardImage = styled.div`
  ${tw`flex-none mr-4 sm:mr-0 overflow-hidden relative`}
  ${tw`w-24 sm:w-40`}

  img {
    ${tw`h-24 sm:h-40 w-auto max-w-none`}
    margin-left: 50%;
    transform: translateX(-50%);
  }
`

export const CardContent = styled.div`
  ${tw`flex flex-col flex-grow justify-between p-3 pl-0 sm:pl-3`}
`

export const CardLink = styled('a')`
  ${tw`no-underline inline-block flex-grow cursor-pointer sm:flex-grow flex flex-col justify-center`}
  :after {
    ${tw`absolute top-0 right-0 bottom-0 left-0`}
    content: ' ';
  }
`

export const CardHeading = styled(Heading)`
  ${tw`sm:overflow-hidden mb-0`}
  @media (min-width: ${getScreenSizes().sm}px) {
    max-height: 85px;
  }
  @media (min-width: ${getScreenSizes().lg}px) {
    max-height: 65px;
  }
`

export const AddToBagCTA = styled(Button)`
  min-width: 6rem;
  min-height: 2rem;
`

export const CardFooter = styled.div`
  ${tw`flex flex-row items-center flex-none`}
`

export const Badge = styled.div`
  ${tw`flex-grow my-0 sm:my-0`}
`

export const Actions = styled.div`
  ${tw`flex flex-grow-0 items-center ml-auto`}
  ${tw`justify-between sm:w-auto`}

  > button {
    ${tw`ml-3 z-10`}
  }
`

export const CardQuantity = styled.div`
  ${tw`absolute flex items-center justify-center`}
  height: 1.875em;
  width: 1.875em;
  left: 0;
  top: 0;
  transform: translate(-35%, -35%);
  border-radius: 50%;
  ${tw`bg-grey-700 text-white text-xs tracking-xl`}
`

export const CardWrapper = styled.div`
  ${tw`relative py-5 sm:py-0`}
  ${tw`border-b border-grey-200`}
  ${tw`sm:border`}
  [name="recommendations"] & {
    ${tw`border-b-0 pb-0 sm:border-b`}
  }
  @media (max-width: ${getScreenSizes().sm}px) {
    &:first-of-type {
      ${tw`pt-0`}
    }
    &:last-of-type {
      ${tw`mb-6 last:border-b-0`}
    }
  }
`
