import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useRouter } from 'next/router'
import { AriaLive } from '@pretamanger/component-library'
import {
  coffeeWizardDefaults,
  coffeeWizardProps,
  productShape as product
} from '@proptypes'
import LocaleLink from '#src/common/components/locale-link'
import { DietaryBadge, NewBadge } from '#src/common/components/badge'
import Translation, { translate } from '#src/common/components/translation'
import { OutOfStock } from '#src/common/components/out-of-stock'
import { OrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import {
  hasFoodCustomisationGroups,
  hasMultiplePrices,
  isNew,
  strictSlugify
} from '#src/products/util'
import { CoffeeWizardButton, isCoffeeWizardProduct } from '#src/coffee-wizard'
import {
  Actions,
  AddToBagCTA,
  Badge,
  CardBody,
  CardContent,
  CardFooter,
  CardHeading,
  CardImage,
  CardLink,
  CardQuantity,
  CardWrapper
} from './styles'
import { fireEvent } from '#src/common/lib/events'
import { PRODUCT_CLICK } from '#src/common/lib/events/topics'
import { getCollectionPath } from '#src/common/lib/get-collection-path'
import ProductCalories from '#src/common/components/product-calories'
import CustomNextImage from '#src/common/components/custom-image-component'

const ProductCard = ({
  children,
  disableNavigation,
  ctaType,
  position,
  selectedCategoryName,
  ...product
}) => {
  const { locale, push } = useRouter()
  const collectionPath = getCollectionPath(locale)
  const routePrefix = `/${collectionPath}`
  const {
    isLoading: bagLoading,
    bag,
    addItemToBag,
    addItemState
  } = useContext(OrderAheadBagContext)
  const {
    coffeeWizard,
    id,
    image,
    isOnStock,
    name,
    newUntil,
    nutritionalData = [],
    price,
    sku,
    suitableForVegans,
    suitableForVegetarians,
    unavailableForOrderAhead,
    categories
  } = product
  const productQuantity = bag?.productQuantities?.[id]

  const getImage = () => {
    // eslint-disable-next-line react/prop-types
    const _getImage = h => `${image?.src}?fm=jpg&fl=progressive&h=${h}`

    const imageSources = {
      xs: _getImage(70),
      sm: _getImage(160),
      md: _getImage(160),
      lg: _getImage(128),
      xl: _getImage(128)
    }

    // eslint-disable-next-line react/prop-types
    return (
      <CustomNextImage
        sources={imageSources}
        img={{ src: _getImage(128) }}
        alt={image?.alt || name}
        ariaHidden={true}
      />
    )
  }
  const diet = suitableForVegans
    ? 'vg'
    : suitableForVegetarians
    ? 'v'
    : undefined

  const _hasCustomisationGroups = hasFoodCustomisationGroups(product)
  const pdpURL = `${routePrefix}/products/${sku}/${encodeURIComponent(
    strictSlugify(name)
  )}`
  const handleAddToBag = async () =>
    _hasCustomisationGroups ? push(pdpURL) : addItemToBag(product, ctaType)

  let addProductAlert = ''
  let isProductBeingAdded = false

  if (addItemState?.sku === sku) {
    isProductBeingAdded = addItemState?.isAdding
    addProductAlert = isProductBeingAdded ? (
      <Translation id='product.activity.addingItemToBag' />
    ) : (
      <Translation id='product.activity.itemAddedToBag' tokens={{ name }} />
    )
  }
  const onProductClick = () => {
    fireEvent(PRODUCT_CLICK, {
      position,
      selectedCategoryName,
      product: { sku, categories, name, price }
    })
  }

  const ctaDisabled = bagLoading || unavailableForOrderAhead
  const ctaLabelForAvailableProduct = _hasCustomisationGroups
    ? 'product.view.cta.label'
    : 'product.add-to-bag.label'
  const ctaTranslation = unavailableForOrderAhead
    ? 'product.add-to-bag.disabled'
    : ctaLabelForAvailableProduct
  const displayCoffeeWizard =
    coffeeWizard.enabled && isCoffeeWizardProduct(product)
  const newProduct = isNew(newUntil)
  const visuallyHiddenName = <span className='sr-only'>{`: ${name}`}</span>

  let cardAriaLabel = name
  if (newProduct) {
    cardAriaLabel += `, ${translate('product.badge.new')?.value}`
  }
  if (productQuantity) {
    cardAriaLabel += `, ${
      translate('bag.item.quantity.option.aria', locale, {
        count: productQuantity
      })?.value
    }`
  }

  const callToAction = () => {
    if (!isOnStock) {
      return (
        <OutOfStock>
          <Translation id='product.status.outOfStock' />
          {visuallyHiddenName}
        </OutOfStock>
      )
    }
    if (displayCoffeeWizard) {
      const _hasMultiplePrices = hasMultiplePrices(product)
      return (
        <>
          {!unavailableForOrderAhead && price && (
            <p>
              <span aria-hidden>
                {price.localisedPrice}
                {_hasMultiplePrices && '+'}
              </span>
              <span className='sr-only'>
                {_hasMultiplePrices ? (
                  <Translation
                    id='product.addToBag.cta.price.starting.from'
                    tokens={{ price: price.localisedPrice }}
                  />
                ) : (
                  price.localisedPrice
                )}
              </span>
            </p>
          )}
          <CoffeeWizardButton
            compact
            disabled={ctaDisabled}
            onClick={() => coffeeWizard.load(product)}
          >
            {visuallyHiddenName}
          </CoffeeWizardButton>
        </>
      )
    }
    return (
      <>
        {!unavailableForOrderAhead && <p>{price?.localisedPrice}</p>}
        <AriaLive role='alert' className='sr-only'>
          {addProductAlert}
        </AriaLive>
        <AddToBagCTA
          activity={isProductBeingAdded}
          data-testid='add-to-bag-plp'
          styleType='quaternary'
          onClick={handleAddToBag}
          disabled={ctaDisabled}
          compact
        >
          {!isProductBeingAdded && <Translation id={ctaTranslation} />}
          {visuallyHiddenName}
        </AddToBagCTA>
      </>
    )
  }

  return (
    <CardWrapper id={`product-list-item-${id}`} data-testid='product-list-item'>
      <CardBody>
        {image?.src && (
          <CardImage>
            {getImage()}
            {newProduct && <NewBadge key={`${id}-badge-new`} variant='tight' />}
          </CardImage>
        )}
        <CardContent>
          {productQuantity > 0 && (
            <CardQuantity aria-hidden='true'>{productQuantity}</CardQuantity>
          )}
          {disableNavigation ? (
            <CardHeading level='h3' styleOverride='heading2XsLight'>
              {name}
            </CardHeading>
          ) : (
            <LocaleLink href={pdpURL}>
              <CardLink
                onClick={onProductClick}
                aria-label={cardAriaLabel}
                data-testid='product-link'
              >
                <CardHeading level='h3' styleOverride='headingBodyLg'>
                  {name}
                </CardHeading>
                <ProductCalories
                  classes='text-grey-700 text-xs mb-1'
                  baristaBeverageDetails={product.baristaBeverageDetails}
                  nutritionalData={nutritionalData}
                />
              </CardLink>
            </LocaleLink>
          )}

          <CardFooter>
            {diet && (
              <Badge>
                <DietaryBadge diet={diet} />
              </Badge>
            )}
            <Actions data-testid='product-actions'>{callToAction()}</Actions>
          </CardFooter>
        </CardContent>
      </CardBody>
      {children}
    </CardWrapper>
  )
}

ProductCard.propTypes = {
  ...product,
  children: PropTypes.node,
  coffeeWizard: coffeeWizardProps,
  disableNavigation: PropTypes.bool,
  ctaType: PropTypes.string,
  selectedCategoryName: PropTypes.string,
  position: PropTypes.number
}

ProductCard.defaultProps = {
  children: null,
  coffeeWizard: coffeeWizardDefaults,
  disableNavigation: false,
  selectedCategoryName: '',
  position: -1
}

export default ProductCard
