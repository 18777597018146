import PropTypes from 'prop-types'
import { QtySelect, SelectContainer } from './styles'
import React from 'react'
import { fallbackLocaleCode } from '#constants'
import { translate } from '#src/common/components/translation'

const createQtySelectOptions = (maxQty, type, locale) =>
  new Array(maxQty).fill({}).map((curr, i) => ({
    id: (i + 1).toString(),
    value: i + 1,
    label: translate(`coffee-wizard.${type}.qty-suffix`, locale, {
      count: i + 1
    })?.value
  }))

const QtySelectBox = ({ groupId, option, locale, onSelectChange }) => {
  return (
    <SelectContainer>
      <QtySelect
        className='w-4/12'
        id={option.sku + '-select'}
        name={groupId}
        onChange={value => onSelectChange(value, option)}
        options={createQtySelectOptions(option.maxQty, groupId, locale)}
      />
    </SelectContainer>
  )
}

QtySelectBox.propTypes = {
  groupId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    localisedPrice: PropTypes.string.isRequired,
    maxQty: PropTypes.number,
    defaultQty: PropTypes.number
  }).isRequired
}

QtySelectBox.defaultProps = {
  locale: fallbackLocaleCode
}

export default QtySelectBox
