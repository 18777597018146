import PropTypes from 'prop-types'
import { RowItem, MultiSelectFieldset } from './styles'
import React, { useState } from 'react'
import { fallbackLocaleCode } from '#constants'
import QtySelectBox from '#src/coffee-wizard/components/multi-select/qty-select'
import CheckboxAndPrice from '#src/coffee-wizard/components/checkbox-price'

const shouldShowQtySelect = (selectState, option) =>
  selectState.find(
    obj => obj.sku === option.sku && obj.showQtySelector === true
  ) && option.maxQty > 1

const MultiSelect = ({ options, groupId, handleChange, children, locale }) => {
  const initialState = options.map(option => ({
    sku: option.sku,
    showQtySelector: false
  }))
  const [selectQtyState, setSelectQtyState] = useState(initialState)
  const onCheckboxChange = (e, data) => {
    const { checked } = e.target
    const quantity = 1
    const updatedData = selectQtyState.map(x =>
      x.sku === data.sku ? { ...x, showQtySelector: checked } : x
    )
    setSelectQtyState(updatedData)
    handleChange(groupId, data, checked, quantity)
  }

  const onSelectChange = (quantity, data) => {
    handleChange(groupId, data, false, parseInt(quantity), true)
  }

  return (
    <MultiSelectFieldset>
      <legend>{children}</legend>
      {options
        .filter(option => option.name)
        .map(option => (
          <RowItem key={option.name}>
            <CheckboxAndPrice
              groupId={groupId}
              option={option}
              onCheckboxChange={onCheckboxChange}
            />
            {shouldShowQtySelect(selectQtyState, option) && (
              <>
                <QtySelectBox
                  groupId={groupId}
                  option={option}
                  onSelectChange={onSelectChange}
                  locale={locale}
                />
              </>
            )}
          </RowItem>
        ))}
    </MultiSelectFieldset>
  )
}

MultiSelect.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
      localisedPrice: PropTypes.string.isRequired,
      maxQty: PropTypes.number,
      defaultQty: PropTypes.number
    })
  ).isRequired,
  groupId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

MultiSelect.defaultProps = {
  locale: fallbackLocaleCode
}

export default MultiSelect
